import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../styles/Herosection.css'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

// gsap importing 
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import SplitType from 'split-type'
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);


function HeroSection() {

    useGSAP(() => {
        var split = new SplitType(".HeroSection .content h1", { type: "chars" });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".HeroSection .content h1",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })

        tl.from(split.chars, {
            y: -20,
            x: 40,
            opacity: 0,
            duration: 0.3,
            stagger: 0.05,

        });
        tl.from(".HeroSection .content p", {
            opacity: 0,
            duration: 1,


        }, "-=1");
    }, []);

    return (
        <div className='HeroSection'>
            <Swiper

                direction={'horizontal'}
                spaceBetween={0}
                centeredSlides={true}
                effect={'fade'}


                autoplay={{
                    delay: 4500,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, EffectFade]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/WhatsApp Image 2024-08-06 at 12.16.32_1f59884b.webp" alt="" />
                    </div>

                </SwiperSlide>


                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/WhatsApp Image 2024-08-06 at 12.16.31_2f7dce78.webp" alt="" />
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-container">
                        <img src="img/WhatsApp Image 2024-08-06 at 12.16.33_a57c3a39.webp" alt="" />
                    </div>

                </SwiperSlide>
            </Swiper>
            <div className="content">
                <h1>Experience Luxury and Serenity at Sahya Regency</h1>
                <p>Discover unparalleled comfort at Sahya Regency, a premier star facility hotel situated amidst serene surroundings. Our elegant accommodations and exceptional services are designed to provide a delightful experience for every guest.</p>
            </div>
        </div>
    )
}

export default HeroSection