import React from 'react';
import '../../../styles/Testimonials.css';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';


// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Testimonials() {

  useGSAP(() => {
        
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".Testimonials",
            start: "top 50%",
            end: "top 20%",
            toggleActions: "play none none none",
        }
    })
    var split = new SplitType(".Testimonials .container h2", { type: "chars" });

    tl.from(split.chars, {
        // y: -60,
        y: -20,
        x: 30,
        opacity: 0,
        duration: 0.2,
        stagger: 0.04,

    });
    tl.to(".Testimonials .container  p", {
        opacity: 1,
        duration: 1,


    }, "-=1.6");
    
    


}, []);
  return (
    <div className='Testimonials'>
      <div className="container">
        <div className="row">
          <div className="left-col">
            <div className="img-container" data-aos="zoom-out" data-aos-duration="1000">
              <img src="img/WhatsApp Image 2024-08-06 at 12.16.32_c0730e37.webp" alt="testimonials" />
            </div>
            <div className="icon">
              <i class="fa-solid fa-quote-left"></i>
            </div>
          </div>
          <div className="right-col">
            <div className="top-section" data-aos="fade" data-aos-duration="1000">
              <h3 className='section-heading'>Testimonials</h3>
              <h2>Customer happiness is at the heart of everything we do</h2>
              <p>See what our clients say about their experience with Sahya Regency.</p>
            </div>
            <div className="carousels" data-aos="fade" data-aos-duration="1000">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, EffectFade, Navigation, Pagination]}
                className="mySwiper"

                breakpoints={{
                  992: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  }
                }}
              >
                <SwiperSlide>
                  <div className="card">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab necessitatibus quibusdam perspiciatis assumenda, ut quos ipsa dolores similique doloribus pariatur quod ea debitis! Est cupiditate pariatur odio corrupti, eaque vel.</p>
                    <div className="profile">
                      <div className="img-container">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRwt02MA_gectNR16dYjcv0VfJe84wEfxAEg&s" alt="profile pic" />
                      </div>
                      <div className="content">
                        <h3>Person Name</h3>
                        <div className="five-star-icon">
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab necessitatibus quibusdam perspiciatis assumenda, ut quos ipsa dolores similique doloribus pariatur quod ea debitis! Est cupiditate pariatur odio corrupti, eaque vel.</p>
                    <div className="profile">
                      <div className="img-container">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRwt02MA_gectNR16dYjcv0VfJe84wEfxAEg&s" alt="profile pic" />
                      </div>
                      <div className="content">
                        <h3>Person Name</h3>
                        <div className="five-star-icon">
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab necessitatibus quibusdam perspiciatis assumenda, ut quos ipsa dolores similique doloribus pariatur quod ea debitis! Est cupiditate pariatur odio corrupti, eaque vel.</p>
                    <div className="profile">
                      <div className="img-container">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRwt02MA_gectNR16dYjcv0VfJe84wEfxAEg&s" alt="profile pic" />
                      </div>
                      <div className="content">
                        <h3>Person Name</h3>
                        <div className="five-star-icon">
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab necessitatibus quibusdam perspiciatis assumenda, ut quos ipsa dolores similique doloribus pariatur quod ea debitis! Est cupiditate pariatur odio corrupti, eaque vel.</p>
                    <div className="profile">
                      <div className="img-container">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRwt02MA_gectNR16dYjcv0VfJe84wEfxAEg&s" alt="profile pic" />
                      </div>
                      <div className="content">
                        <h3>Person Name</h3>
                        <div className="five-star-icon">
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab necessitatibus quibusdam perspiciatis assumenda, ut quos ipsa dolores similique doloribus pariatur quod ea debitis! Est cupiditate pariatur odio corrupti, eaque vel.</p>
                    <div className="profile">
                      <div className="img-container">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRwt02MA_gectNR16dYjcv0VfJe84wEfxAEg&s" alt="profile pic" />
                      </div>
                      <div className="content">
                        <h3>Person Name</h3>
                        <div className="five-star-icon">
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                          <span><i class="fa-solid fa-star"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials