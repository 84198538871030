import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../styles/TouristAttraction.css';
import '../../../styles/FacilityList.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// Import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import SplitType from 'split-type';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function FacilityList() {
    const [activeIndex, setActiveIndex] = useState(0);


    useGSAP(() => {


        const containers = document.querySelectorAll('.FacilityList .container');

        containers.forEach((container) => {
            // Apply ScrollTrigger to each container
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: container,
                    start: "top 60%",
                    end: "top 20%",
                    toggleActions: "play none none none",
                }
            });

            var split = new SplitType(container.querySelector('h2'), { type: "chars" });

            tl.from(split.chars, {
                y: -20,
                x: 30,
                opacity: 0,
                duration: 0.3,
                stagger: 0.06,
            });
            tl.to(container.querySelector('p'), {
                opacity: 1,
                duration: 1,
            });
    
            // Animate the bullet points inside the current container
            tl.to(container.querySelectorAll('.bullet-points li'), {
                opacity: 1,
                duration: 0.6,
                stagger: 0.15,
            }, "-=0.7");
        });

    }, []);
    return (
        <div className='FacilityList'>
            <div className="container">
                <h2>Accomodation</h2>

                <div className="row">
                    <div className="left-col" data-aos="zoom-in" data-aos-duration="1000">

                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            effect={'fade'}
                            slidesPerView={1}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[EffectFade, Navigation, Pagination]}
                            className="mySwiper"
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.33_5f9206bc.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.33_49305123.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.32_c0730e37.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.32_1f59884b.webp" alt="" />
                                </div>
                            </SwiperSlide>

                        </Swiper>



                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">
                        <p>Our hotel offers a selection of comfortable accommodations, including 2 Suite Rooms, a Queen Suite, and 9 Standard Rooms. All rooms are air-conditioned and equipped with Wi-Fi. We also provide laundry services to ensure your stay is convenient and enjoyable.</p>

                        <ul className='bullet-points'>
                            <li><span><i class="fa-solid fa-check"></i></span>2 Suite Rooms</li>
                            <li><span><i class="fa-solid fa-check"></i></span>1 Queen Suite</li>
                            <li><span><i class="fa-solid fa-check"></i></span>9 Standard Rooms</li>
                            <li><span><i class="fa-solid fa-check"></i></span>All Rooms are Air Conditioned</li>
                            <li><span><i class="fa-solid fa-check"></i></span>Wi-Fi Available </li>
                            <li><span><i class="fa-solid fa-check"></i></span>Laundry Services Available</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container even">
                <h2 >Event and Meeting Spaces</h2>

                <div className="row">
                    <div className="left-col" data-aos="zoom-in" data-aos-duration="1000">

                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            effect={'fade'}
                            slidesPerView={1}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[EffectFade, Navigation, Pagination]}
                            className="mySwiper"
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.31_2f7dce78.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.31_81f2e685.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.30_57d0e073.webp" alt="" />
                                </div>
                            </SwiperSlide>

                        </Swiper>



                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">
                        <p>Our hotel features versatile event and meeting spaces, including a spacious banquet hall that can accommodate up to 400 guests and a conference room with a capacity of 100 guests. These spaces are ideal for hosting a variety of events, from large celebrations to professional meetings.</p>

                        <ul className='bullet-points'>
                            <li><span><i class="fa-solid fa-check"></i></span>350pax Banquet Hall</li>
                            <li><span><i class="fa-solid fa-check"></i></span>40 Mini Banquet</li>
                            <li><span><i class="fa-solid fa-check"></i></span>40pax Conference Room</li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="container">
                <h2 >Dining</h2>

                <div className="row">
                    <div className="left-col" data-aos="zoom-in" data-aos-duration="1000">

                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            effect={'fade'}
                            slidesPerView={1}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[EffectFade, Navigation, Pagination]}
                            className="mySwiper"
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.32_6c391f93.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/WhatsApp Image 2024-08-06 at 12.16.31_614678e7.webp" alt="" />
                                </div>
                            </SwiperSlide>


                        </Swiper>



                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">
                        <p>Our hotel features a multi-cuisine restaurant that offers a diverse selection of dishes from around the world. Whether you're in the mood for local specialties or international favorites, our restaurant provides a delightful dining experience to suit every taste.</p>

                        <ul className='bullet-points'>
                            <li><span><i class="fa-solid fa-check"></i></span>Multi cuisine Restaurant</li>
                            <li><span><i class="fa-solid fa-check"></i></span>Elegant Ambiance</li>
                            <li><span><i class="fa-solid fa-check"></i></span>Spacious Dining Area</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container even">
                <h2>Parking Space</h2>

                <div className="row">
                    <div className="left-col" data-aos="zoom-in" data-aos-duration="1000">

                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            effect={'fade'}
                            slidesPerView={1}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[EffectFade, Navigation, Pagination]}
                            className="mySwiper"
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="../img/parking.webp" alt="" />
                                </div>
                            </SwiperSlide>

                        </Swiper>



                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">
                        <p>Our hotel offers ample car parking facilities, providing plenty of space for all our guests. Whether you're arriving for a short stay or an extended visit, you can enjoy the convenience and security of our well-maintained parking area.</p>
                        <ul className='bullet-points'>
                            <li><span><i class="fa-solid fa-check"></i></span>Amble Car Parking</li>
                            <li><span><i class="fa-solid fa-check"></i></span>Convenient Location</li>
                            <li><span><i class="fa-solid fa-check"></i></span>Secure Parking</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacilityList